<template>
    <div>
        <v-row class="justify-center">
            <v-card width="320" class="mb-5">
                <v-card-title class="h3">
                    {{ title }}
                </v-card-title>
                <v-img
                    height="150px"
                    :src="imageUrl"
                >
                </v-img>

                <v-card-subtitle class="text-right h4">
                    <span style="color: royalblue">Price: {{ price }}</span>
                </v-card-subtitle>
                <v-card-text>
                    <v-list dense outlined class="rounded-4">
                        <v-subheader>INCLUDES</v-subheader>

                        <v-list-item
                            v-for="feature in features"
                            :key="feature.label" >

                            <v-list-item-content>
                                <v-list-item-title v-text="feature.label"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-card-actions>
                    <v-row class="mt-3 mb-3">
                        <v-spacer/>
                        <stripe-checkout
                            ref="checkoutRef"
                            mode="subscription"
                            :pk="publicKey"
                            :session-id="sessionId"
                            @loading="v => loading = v"
                        />

                        <v-btn @click="submit" color="secondary" class="mr-6" :disabled="disabled">Subscribe</v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-row>
    </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import stripeApi from "@/api/stripe";

export default {
    name: "Subscription",

    /************************************************************************************
     *                                    PROPS
     * **********************************************************************************/
    props: [
        'title',
        'features',
        'price',
        'stripePrice',
        'imageUrl',
        'disabled',
        'subscriptionType',
    ],

    /************************************************************************************
     *                                 COMPONENTS
     * **********************************************************************************/
    components: {
        StripeCheckout
    },

    /************************************************************************************
     *                                    DATA
     * **********************************************************************************/
    data() {
        this.publicKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY;

        return {
            loading: false,
            lineItems: [],
            sessionId: null,
            successURL: process.env.VUE_APP_REDIRECT_URI + '/tenant/manage/' + this.$route.params.tenant_id + '?subscribed=true',
            cancelURL: process.env.VUE_APP_REDIRECT_URI + '/tenant/manage/' + this.$route.params.tenant_id + '?subscribed=false',
            dataToSend: null,
        }
    },

    /************************************************************************************
     *                                    COMPUTED
     * **********************************************************************************/
    computed: {

    },

    /************************************************************************************
     *                                    ACTIONS
     * **********************************************************************************/
    actions: {

    },

    /************************************************************************************
     *                                    METHODS
     * **********************************************************************************/
    methods: {
        async getStripeCheckoutSession( info) {
            const {data} = await stripeApi.getStripeCheckoutSession(info);

            return data;
        },

        submit() {
            let params = this.$route.params;

            if (!params.tenant_id) {
                this.$swal.fire({
                    timer: 4000,
                    icon: "warning",
                    title: "Ooops",
                    text: "Please select a tenant",
                });

                return;
            }


            let subscriptionFor = this.subscriptionType.split('_')[0];


            //if the users selects warehouse and inventory a popup is displayed asking if he wants to continue
            //normally a user with WMS don't need inventory or vice-versa
            if ((this.$store.getters.isWarehouseSubscriptionActive && subscriptionFor === 'INVENTORY')
                || (this.$store.getters.isInventorySubscriptionActive && subscriptionFor === 'WMS')) {
                this.$swal.fire({
                    title: 'You will not be able to undo this action',
                    html: 'Are you sure you want to subscribe to both services: <strong><em>W.M.S.</em></strong> & <strong><em>Inventory Management</em></strong>?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, continue!',
                    cancelButtonText: 'No, cancel'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.callStripeApi(params); //go to stripe checkout
                    } else {
                        return; //user canceled
                    }
                })
            } else {
                //not asked
                this.callStripeApi(params); //go to stripe checkout
            }
        },

        callStripeApi(params) {
            this.getSelectedSubscriptions();

            this.dataToSend = {
                tenant_id: params.tenant_id,
                items: this.lineItems,
                successUrl: this.successURL,
                cancelUrl: this.cancelURL
            };


            this.getStripeCheckoutSession(this.dataToSend).then((success) => {
                this.sessionId = success.id;
                this.$refs.checkoutRef.redirectToCheckout();
            }).catch((error) => {
                this.$swal.fire({
                    timer: 4000,
                    icon: "error",
                    title: "Checkout failed",
                    text: error.data || "Error creating the subscription",
                    toast: true,
                    position: "bottom-end",
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                });
            });
        },


        getSelectedSubscriptions() {
            let items = [];

            items.push(JSON.parse('{"price": "' + this.stripePrice + '", "quantity": 1 }'));

            this.lineItems = items; //list with subscriptions
        },
    },

    /************************************************************************************
     *                                    WATCH
     * **********************************************************************************/
    watch: {

    },

    /************************************************************************************
     *                                    CREATED
     * **********************************************************************************/
    created() {

    },

    /************************************************************************************
     *                                  MOUNTED
     * **********************************************************************************/
    mounted() {

    },
}
</script>

<style scoped>

</style>
