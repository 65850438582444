<template>
  <v-card min-height="60vh">
    <v-card-title>
      <v-tabs align-with-title v-model="tabs">
        <v-tabs v-model="tabs">
          <v-tab v-for="item in createTabs()" :key="item.tab" :disabled="item.disabled">
            {{ item.tab }}
          </v-tab>
        </v-tabs>
      </v-tabs>
    </v-card-title>
    <v-container>
      <v-divider></v-divider>
    </v-container>
    <v-expand-transition>
      <v-card-text>
        <v-tabs-items v-model="tabs">
          <v-tab-item v-for="item in createTabs()" :key="item.tab">
            <v-card flat>
              <v-card-text>
                <component type="buyer" :is="TabsComponent(item)"></component>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import TenantForm from "@/components/tenant/TenantForm.vue";
import TenantApiForm from "@/components/tenant/TenantApiForm"
import SubscriptionForm from '../../components/tenant/SubscriptionForm.vue';
// import PaymentForm from '../../components/tenant/PaymentForm.vue';
import ModulesConfig from '../../components/tenant/ModulesConfig.vue';
// import TenantUsers from '../../components/tenant/TenantUsers.vue';
import TenantUserList from '../../components/users/TenantUserList.vue';
import EmailsForm from "../../components/tenant/EmailsForm.vue";
import { mapActions } from 'vuex';

export default {
  /************************************************************************************
   *                                    COMPONENTS
   * **********************************************************************************/
  components: {

  },

  /************************************************************************************
   *                                    DATA
   * **********************************************************************************/
  data: () => ({
    tabs: null,
  }),

  /************************************************************************************
   *                                    COMPUTED
   * **********************************************************************************/
  computed:
  {
    currentRouteName() {
      return this.$route.name;
    },
  },

  /************************************************************************************
   *                                    ACTIONS
   * **********************************************************************************/
  actions: {

  },

  /************************************************************************************
   *                                    METHODS
   * **********************************************************************************/
  methods: {
    ...mapActions([
      'getTenantsById', 'getTenantActiveSubscriptions', 'getSubscriptionsData'
    ]),


    getActiveSubscriptions(tenantId) {
      if (!tenantId) {
        return;
      }

      this.dataToSend = {
        tenant_id: tenantId,
      };

      this.getTenantActiveSubscriptions(this.dataToSend).catch(() => {
        this.$swal.fire({
          timer: 4000,
          icon: "error",
          title: "Error fetching tenant Subscriptions",
          toast: true,
          position: "bottom-end",
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true,
        });
      });
    },

    getSubscriptionsInfo() {
      this.getSubscriptionsData().catch(() => {
        this.$swal.fire({
          timer: 4000,
          icon: "error",
          title: "Error fetching the Subscriptions Data",
          toast: true,
          position: "bottom-end",
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true,
        });
      });
    },

    createTabs() {
      let disable = this.$route.params.tenant_id ? false : true;

      let allSubscriptionInactive = this.$store.getters.allSubscriptionsAreInactive;
      let userType = this.$store.getters.currentUserType.key;

      let items = [
        { tab: "General", content: "Tab 1 Content", disabled: (allSubscriptionInactive && userType !== "master_admin") },
        { tab: "Subscription", content: "Tab 2 Content", disabled: (disable) },
        // { tab: "Payment", content: "Tab 3 Content", disabled: (this.$route.params.tenant_id ? false : true) },
        { tab: "Modules", content: "Tab 4 Content", disabled: (disable || (allSubscriptionInactive && userType !== "master_admin")) },
        { tab: "Users", content: "Tab 5 Content", disabled: (disable || (allSubscriptionInactive && userType !== "master_admin")) },
        { tab: "Email", content: "Tab 6 Content", disabled: (disable || (allSubscriptionInactive && userType !== "master_admin")) },
        { tab: "Api", content: "Tab 7 Content", disabled: (disable || (allSubscriptionInactive && userType !== "master_admin")) },
      ];

      return items;
    },

    TabsComponent(item = null) {
      if (item) {
        const tabsComponentMap = {
          "General": TenantForm,
          "Subscription": SubscriptionForm,
          // "Payment": PaymentForm,
          "Email": EmailsForm,
          "Modules": ModulesConfig,
          "Users": TenantUserList,
          "Api": TenantApiForm
        }
        return tabsComponentMap[item.tab]
      }
    }
  },

  /************************************************************************************
   *                                    WATCH
   * **********************************************************************************/
  watch: {

  },

  /************************************************************************************
   *                                    CREATED
   * **********************************************************************************/
  created() {
    const self = this;
    const param = self.$route.params;

    if (param && param.tenant_id) {
      self.getTenantsById(param).then((tenant) => {
        if (tenant.id) {
          self.$store.commit('setCurrentTenant', tenant);
          // self.$store.commit('setTenantFrmDataAll', tenant);
        }
      });
    } else {
      self.$store.commit('clearTenantFrmData');
    }

    this.getActiveSubscriptions(param.tenant_id);
  },

  /************************************************************************************
   *                                  MOUNTED
   * **********************************************************************************/
  mounted() {
    this.getSubscriptionsInfo();
  },
};
</script>