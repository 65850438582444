<template>
    <div>
        <v-container class="d-flex justify-content-center">
            <v-spacer></v-spacer>

            <v-row style="width: 70%">
                <v-col v-for="subscription in $store.getters.getWarehouseSubscriptionsData" :key="subscription.type">
                    <subscription
                        :title="subscription.description"
                        :price="subscription.price"
                        :stripe-price="subscription.price_tag"
                        :features="features"
                        :image-url="subscription.image_path"
                        :subscription-type="'WMS_' + subscription.type"
                        :disabled="$store.getters.isWarehouseSubscriptionActive"
                        >
                    </subscription>
                </v-col>
            </v-row>

            <v-spacer></v-spacer>
        </v-container>
    </div>
</template>

<script>
import Subscription from "@/components/common/Subscription";

export default {
    name: "WmsSubscriptions",

    /************************************************************************************
     *                                    COMPONENTS
     * **********************************************************************************/
    components: {
        Subscription,
    },

    /************************************************************************************
     *                                    DATA
     * **********************************************************************************/
    data: () => ({
        features: [
            {
                label: 'Purchase Orders',
            },
            {
                label: 'Traffic',
            },
            {
                label: 'Receiving',
            },
            {
                label: 'Putaway',
            },
            {
                label: 'Picking',
            },
            {
                label: 'Cargo Loading',
            },
            {
                label: 'LPNs',
            },
            {
                label: 'Transfer Orders',
            },
            {
                label: 'Inventory Management',
            },
            {
                label: 'Cycle Counts',
            },
            {
                label: 'Reports',
            },
        ],
    }),

    /************************************************************************************
     *                                    COMPUTED
     * **********************************************************************************/
    computed: {

    },

    /************************************************************************************
     *                                    ACTIONS
     * **********************************************************************************/
    actions: {

    },

    /************************************************************************************
     *                                    METHODS
     * **********************************************************************************/
    methods: {

    },

    /************************************************************************************
     *                                    WATCH
     * **********************************************************************************/
    watch: {

    },

    /************************************************************************************
     *                                    CREATED
     * **********************************************************************************/
    created() {

    },

    /************************************************************************************
     *                                  MOUNTED
     * **********************************************************************************/
    mounted() {

    },
}
</script>

<style scoped>

</style>
