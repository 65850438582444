<template>
    <v-container>
      <v-row>
        <v-spacer/>
        <v-btn color="secondary" :href="returnToUrl" v-show="showManagePortalButton">
          Manage Subscriptions
        </v-btn>
      </v-row>

      <v-row>
        <v-tabs v-model="tabs"
                centered
                class="mb-3"
                icons-and-text>
          <v-tab v-for="item in items" :key="item.tab" :disabled="item.disabled">
              {{ item.tab }}
              <v-icon>{{item.icon}}</v-icon>
          </v-tab>
        </v-tabs>

        <v-divider/>

        <v-col>
          <v-tabs-items v-model="tabs" >
              <v-tab-item v-for="item in items" :key="item.tab" >
                  <v-container>
                      <component v-bind:is="TabsComponent(item)"></component>
                  </v-container>
              </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>

import WmsSubscriptions from "@/components/tenant/WmsSubscriptions.vue";
import InventorySubscriptions from "@/components/tenant/InventorySubscriptions.vue";
import MarketplaceSubscriptions from "@/components/tenant/MarketplaceSubscriptions.vue";
import KnowYourCustomerSubscription from "@/components/tenant/KnowYourCustomerSubscription.vue";
import stripeApi from "@/api/stripe";
import { mapActions } from "vuex";

export default {
  /************************************************************************************
   *                                    COMPONENTS
   * **********************************************************************************/
  components: {

  },

  /************************************************************************************
   *                                    DATA
   * **********************************************************************************/
  data() {
    this.publicKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY;

    return {
      returnToUrl: process.env.VUE_APP_REDIRECT_URI,
      showManagePortalButton: false,
      tabs: null,
      items: [
          { tab: 'Inventory', icon: 'mdi-order-bool-ascending-variant', disabled: false },
          { tab: 'Warehouse', icon: 'mdi-warehouse', disabled: true },
          { tab: 'Marketplace', icon: 'mdi-shopping-outline', disabled: true },
          { tab: 'Know Your Customer', icon: 'mdi-finance', disabled: true },
      ],
    };
  },

  /************************************************************************************
   *                                    COMPUTED
   * **********************************************************************************/
  computed: {

  },

  /************************************************************************************
   *                                    ACTIONS
   * **********************************************************************************/
  actions: {

  },

  /************************************************************************************
   *                                    METHODS
   * **********************************************************************************/
  methods: {
    ...mapActions(["showSubscriptionMessage"]),

    TabsComponent(item = null) {
        if (item) {
            const tabsComponentMap = {
                "Warehouse": WmsSubscriptions,
                "Inventory": InventorySubscriptions,
                "Marketplace": MarketplaceSubscriptions,
                "Know Your Customer": KnowYourCustomerSubscription,
            }

            return tabsComponentMap[item.tab];
        }
    },

    getBillingUrl() {
      let params = {
        tenantId: this.$route.params.tenant_id,
        returnToUrl: process.env.VUE_APP_REDIRECT_URI + '/tenant/manage/' + this.$route.params.tenant_id,
      }

      this.getBillingPortalUrl(params).then((response) => {
        this.returnToUrl = response.returnToUrl;
        this.showManagePortalButton = true;
      }).catch(() => {
        this.$swal.fire({
          timer: 4000,
          icon: "error",
          title: "Error Creating the Billing Portal",
          toast: true,
          position: "bottom-end",
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true,
        });
      });
    },

    async getBillingPortalUrl(params) {
      const { data } = await stripeApi.getBillingPortalUrl(params);

      return data;
    },
  },

  /************************************************************************************
   *                                    WATCH
   * **********************************************************************************/
  watch: {

  },

  /************************************************************************************
   *                                    CREATED
   * **********************************************************************************/
  created() {

  },

  /************************************************************************************
   *                                  MOUNTED
   * **********************************************************************************/
  mounted() {
    this.getBillingUrl();
    let route = this.$route;
    let router = this.$router;
    let swal = this.$swal;
    let data = { route, swal, router };

    this.showSubscriptionMessage(data);
  },
};
</script>