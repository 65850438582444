<template>
  <v-data-table
      :headers="headers"
      :items="currentTenantUsers"
      :search="search"
      :loading="loading"
      loading-text="Retrieving Data"
      class="elevation-1"
  >

    <template v-slot:item.can_receive_email="{ item }">
      <v-simple-checkbox
          v-model="item.can_receive_email"
          @click="updateUser( item )"
          color="secondary"
      ></v-simple-checkbox>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  /************************************************************************************
   *                                    COMPONENTS
   * **********************************************************************************/
  components: {

  },

  /************************************************************************************
   *                                    PROPS
   * **********************************************************************************/


  /************************************************************************************
   *                                    DATA
   * **********************************************************************************/
  data() {
    return {
      loading: false,
      search: '',
      headers: [
        {
          class: "grey lighten-4 text-uppercase",
          align: "start",
          text: "Name",
          value: "first_name",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "start",
          text: "Last Name",
          value: "last_name",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "start",
          text: "Second Last Name",
          value: "second_last_name",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "end",
          text: "Email",
          value: "email",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Receive Notifications",
          value: "can_receive_email",
        },
      ],
    };
  },

  /************************************************************************************
   *                                    COMPUTED
   * **********************************************************************************/
  computed: {
    ...mapGetters(["currentTenantUsers", "currentUserType", "currentTenant"]),
  },

  /************************************************************************************
   *                                    ACTIONS
   * **********************************************************************************/
  actions: {

  },

  /************************************************************************************
   *                                    METHODS
   * **********************************************************************************/
  methods: {
    ...mapActions(["getTenantsUsers", "updateUserOnly"]),

    updateUser(userData) {
      userData.tenant_id = this.currentTenant.id;

      this.updateUserOnly(userData);
    },

  },

  /************************************************************************************
   *                                    WATCH
   * **********************************************************************************/
  watch: {

  },

  /************************************************************************************
   *                                    CREATED
   * **********************************************************************************/
  created() {
    const userType = this.currentUserType.key;

    if (!this.currentTenant && userType == "master_admin") {
      //Pedir que seleccione un tenant
      return
    }

    const param = this.$route.params;

    if (param.tenant_id || param.supplier_id) {
      this.loading = true;

      this.getTenantsUsers(this.currentTenant).finally(() => {
        this.loading = false;
      });
    } else {
      this.$store.commit('clearTenantUsers');
      this.loading = false;
    }
  },

  /************************************************************************************
   *                                  MOUNTED
   * **********************************************************************************/
  mounted() {

  },
};
</script>