<template>
    <div>
        <v-container class="d-flex justify-content-center">
            <v-row style="width: 70%">
                <v-col v-for="subscription in $store.getters.getInventorySubscriptionsData" :key="subscription.type">
                    <subscription
                        :title="subscription.description"
                        :features="features"
                        :price="subscription.price"
                        :stripe-price="subscription.price_tag"
                        :image-url="subscription.image_path"
                        :subscription-type="'INVENTORY_' + subscription.type"
                        :disabled="$store.getters.isInventorySubscriptionActive"
                        >
                    </subscription>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Subscription from '@/components/common/Subscription';

export default {
    name: "InventorySubscriptions",

    /************************************************************************************
     *                                    COMPONENTS
     * **********************************************************************************/
    components: {
        Subscription
    },

    /************************************************************************************
     *                                    DATA
     * **********************************************************************************/
    data: () => ({
        features: [
            {
                label: 'Purchase Orders',
            },
            {
                label: 'Inbound',
            },
            {
                label: 'Outbound',
            },
            {
                label: 'Transfer Orders',
            },
            {
                label: 'Inventory Management',
            },
            {
                label: 'Cycle Counts',
            },
            {
                label: 'Reports',
            },
        ],
        // inventoryImage: require('@/assets/Allec-New-Logo-I.png'),
    }),

    /************************************************************************************
     *                                    COMPUTED
     * **********************************************************************************/
    computed: {

    },

    /************************************************************************************
     *                                    ACTIONS
     * **********************************************************************************/
    actions: {

    },

    /************************************************************************************
     *                                    METHODS
     * **********************************************************************************/
    methods: {

    },

    /************************************************************************************
     *                                    WATCH
     * **********************************************************************************/
    watch: {

    },

    /************************************************************************************
     *                                    CREATED
     * **********************************************************************************/
    created() {

    },

    /************************************************************************************
     *                                  MOUNTED
     * **********************************************************************************/
    mounted() {

    },
}
</script>

<style scoped>

</style>
