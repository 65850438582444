<template>
    <div>
        <v-container class="d-flex justify-content-center">
            <v-spacer></v-spacer>

            <v-row style="width: 70%">
                <v-col>
                    <contact-us
                        :title="'Know Your Customer'"
                        :image-url="kycImage"
                        :email="email"
                        :phone="phone"
                    >
                    </contact-us>
                </v-col>
            </v-row>

            <v-spacer></v-spacer>
        </v-container>
    </div>
</template>

<script>
import ContactUs from "@/components/common/ContactUs.vue";

export default {
    name: "KnowYourCustomerSubscription",

    /************************************************************************************
     *                                    COMPONENTS
     * **********************************************************************************/
    components: {
        ContactUs
    },

    /************************************************************************************
     *                                    DATA
     * **********************************************************************************/
    data: () => ({
        email: 'contact@allec.io',
        phone: '(939) 204-1439',
        kycImage: require('@/assets/Allec-New-Logo-K.png'),
    }),

    /************************************************************************************
     *                                    COMPUTED
     * **********************************************************************************/
    computed: {

    },

    /************************************************************************************
     *                                    ACTIONS
     * **********************************************************************************/
    actions: {

    },

    /************************************************************************************
     *                                    METHODS
     * **********************************************************************************/
    methods: {

    },

    /************************************************************************************
     *                                    WATCH
     * **********************************************************************************/
    watch: {

    },

    /************************************************************************************
     *                                    CREATED
     * **********************************************************************************/
    created() {

    },

    /************************************************************************************
     *                                  MOUNTED
     * **********************************************************************************/
    mounted() {

    },
}
</script>

<style scoped>

</style>
