<template>
<!--    <div>-->
        <v-parallax class="align-top"
            dark
            src="https://www.wallpaperup.com/uploads/wallpapers/2014/09/26/457770/26ae37e9f34f25d370a71bd647fd9219.jpg"
        >
            <v-row
                align="center"
                justify="center"
            >
                <v-col
                    class="text-center"
                    cols="12"
                >
                    <h1 class="text-h4 font-weight-thin mb-4">
                        Allec Marketplace
                    </h1>
                    <h4 class="subheading">
                        Stay tuned!
                        <br/>
                        We have some exciting news underway.
                    </h4>
                </v-col>
            </v-row>
        </v-parallax>
<!--        <subscription-->
<!--            :title="'Basic: 1 - 5 Warehouses'"-->
<!--            :price="basicPrice"-->
<!--            :features="features"-->
<!--            :image-url="marketplaceImage"-->
<!--            :subscriptionType="'basicMarketplaceSubscription'"-->
<!--            >-->
<!--        </subscription>-->
<!--    </div>-->
</template>

<script>
// import Subscription from "@/components/common/Subscription";

export default {
    name: "MarketplaceSubscriptions",

    /************************************************************************************
     *                                    COMPONENTS
     * **********************************************************************************/
    components: {
        // Subscription,
    },

    /************************************************************************************
     *                                    DATA
     * **********************************************************************************/
    data: () => ({
        basicPrice: '$250',
        features: [
            {
                label: 'Purchase Orders',
            },
            {
                label: 'Inbound',
            },
            {
                label: 'Outbound',
            },
            {
                label: 'Transfer Orders',
            },
            {
                label: 'Inventory Management',
            },
            {
                label: 'Cycle Counts',
            },
            {
                label: 'Reports',
            },
        ],
        marketplaceImage: require('@/assets/Allec-New-Logo-M.png'),
    }),

    /************************************************************************************
     *                                    COMPUTED
     * **********************************************************************************/
    computed: {

    },

    /************************************************************************************
     *                                    ACTIONS
     * **********************************************************************************/
    actions: {

    },

    /************************************************************************************
     *                                    METHODS
     * **********************************************************************************/
    methods: {

    },

    /************************************************************************************
     *                                    WATCH
     * **********************************************************************************/
    watch: {

    },

    /************************************************************************************
     *                                    CREATED
     * **********************************************************************************/
    created() {

    },

    /************************************************************************************
     *                                  MOUNTED
     * **********************************************************************************/
    mounted() {

    },
}
</script>

<style scoped>

</style>
