<template>
    <div>
        <v-row class="justify-center">
            <v-card width="400" >
                <v-card-title class="h3">
                    {{ title }}
                </v-card-title>
                <v-img
                    height="200px"
                    :src="imageUrl"
                >
                </v-img>

                <v-card-text>
                    <v-list two-line>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon >
                                    mdi-phone
                                </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ phone }}</v-list-item-title>
                                <v-list-item-subtitle>Phone</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon >
                                    mdi-email
                                </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ email }}</v-list-item-title>
                                <v-list-item-subtitle>Email</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-row>
    </div>
</template>

<script>

export default {
    name: "ContactUs",

    /************************************************************************************
     *                                    PROPS
     * **********************************************************************************/
    props: [
        'title',
        'email',
        'imageUrl',
        'phone'
    ],

    /************************************************************************************
     *                                    COMPONENTS
     * **********************************************************************************/
    components: {

    },

    /************************************************************************************
     *                                    DATA
     * **********************************************************************************/
    data() {
        return {

        }
    },

    /************************************************************************************
     *                                    COMPUTED
     * **********************************************************************************/
    computed: {

    },

    /************************************************************************************
     *                                    ACTIONS
     * **********************************************************************************/
    actions: {

    },

    /************************************************************************************
     *                                    METHODS
     * **********************************************************************************/
    methods: {

    },

    /************************************************************************************
     *                                    WATCH
     * **********************************************************************************/
    watch: {

    },

    /************************************************************************************
     *                                    CREATED
     * **********************************************************************************/
    created() {

    },

    /************************************************************************************
     *                                  MOUNTED
     * **********************************************************************************/
    mounted() {

    },
}
</script>

<style scoped>

</style>