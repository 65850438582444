<template>
    <validation-observer ref="apiFormObserver" v-slot="{ invalid }">
        <v-container>
            <v-row>
                <v-col>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                Marketplace
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                Coming Soon
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                Inventory Management
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <validation-provider v-slot="{ errors }" name="Quickbooks API Key" :rules="{
                                        required: false,
                                    }">
                                    <v-text-field dense outlined :disabled="!inventorySubscriptionIsActive"
                                        v-model="quickbooksApiKey" :error-messages="errors" label="Quickbooks API Key"
                                        required></v-text-field>
                                </validation-provider>
                                <validation-provider v-slot="{ errors }" name="Clover API Key" :rules="{
                                        required: false,
                                    }">
                                    <v-text-field dense outlined :disabled="!inventorySubscriptionIsActive"
                                        v-model="cloverApiKey" :error-messages="errors" label="Clover API Key"
                                        required></v-text-field>
                                </validation-provider>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                Warehouse Management
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                Coming soon
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>

            </v-row>
            <v-row>
                <v-col>
                    <!-- <v-btn class="mr-4" type="submit" :disabled="invalid" :loading="loadings.formBtn">
                        Submit
                    </v-btn> -->
                    <v-btn @click="submit" :disabled="invalid">Submit</v-btn>
                </v-col>
            </v-row>
        </v-container>


    </validation-observer>
</template>
  
<script>
import { required, digits, max, regex } from "vee-validate/dist/rules";
import {
    extend,
    ValidationObserver,
    ValidationProvider,
    setInteractionMode,
} from "vee-validate";
import { mapActions, mapGetters } from "vuex";

setInteractionMode("eager");

extend("digits", {
    ...digits,
    message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
    ...required,
    message: "{_field_} can not be empty",
});

extend("max", {
    ...max,
    message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
    ...regex,
    message: "{_field_} not is valid",
});

export default {
    /************************************************************************************
     *                                    COMPONENTS
     * **********************************************************************************/
    components: {
        ValidationProvider,
        ValidationObserver,
    },

    /************************************************************************************
     *                                    PROPS
     * **********************************************************************************/
    props: ['type'],

    /************************************************************************************
     *                                    DATA
     * **********************************************************************************/
    data: () => ({
        loadings: {
            formBtn: false,
        },
        quickbooksApiKey: '',
        cloverApiKey: ''
    }),

    /************************************************************************************
     *                                    COMPUTED
     * **********************************************************************************/
    computed: {
        ...mapGetters(["currentTenant", "tenantFormData"]),

        marketplaceSubscriptionIsActive() {
            return this.$store.getters.isMarketPlaceSubscriptionActive;
        },

        inventorySubscriptionIsActive() {
            return this.$store.getters.isInventorySubscriptionActive;
        },

        warehouseSubscriptionIsActive() {
            return this.$store.getters.isWarehouseSubscriptionActive;
        }
    },

    /************************************************************************************
     *                                    ACTIONS
     * **********************************************************************************/
    actions: {

    },

    /************************************************************************************
     *                                    METHODS
     * **********************************************************************************/
    methods: {
        ...mapActions([
            "createTenant",
            "getTenantsById",
            "clearTenantForm",
            "updateTenantApiKeys",
        ]),

        submit() {
            const self = this;
            const valid = this.$refs.apiFormObserver.validate();

            let action = "Updating"

            if (!valid) return;

            self.showNotification({
                type: 3,
                text: `${action} Tenant`,
                persistent: true,
            });


            if (self.currentTenant && self.currentTenant.id) {
                const apiKeys = {
                    api_modules: {
                        "allec-inventory": {
                            module_id: process.env.VUE_APP_INVENTORY_ID,
                            api_keys: [
                                { service_name: "quickbooks", api_key: this.quickbooksApiKey },
                                { service_name: "clover", api_key: this.cloverApiKey }
                            ]
                        }
                    },
                    id: self.currentTenant.id
                }
                /* Update */
                self.updateTenantApiKeys(apiKeys)
                    .then((suss) => {
                        self.showNotification({
                            type: 1,
                            text: suss.message,
                            lngKey: false,
                        });
                    }).catch((err) => {
                        this.$swal.fire({
                            timer: 4000,
                            icon: "error",
                            title: "Tenant Update Failed",
                            text: err,
                            toast: true,
                            position: "bottom-end",
                            timerProgressBar: true,
                            showConfirmButton: false,
                            showCloseButton: true,
                        });
                    }).finally(() => {
                        self.closeNotifications(3);
                    });
            }
        },
    },

    /************************************************************************************
     *                                    WATCH
     * **********************************************************************************/
    watch: {
        currentTenant(tenant = {}) {
            const self = this;
            const param = self.$route.params;

            if (tenant.id && (param.tenant_id || param.supplier_id)) {
                const tenant = self.currentTenant;

                if (tenant.id) {
                    self.$store.commit("setCurrentTenantFormData", tenant);

                    if (tenant.is_supplier) {
                        if (tenant.supplier) {
                            self.$store.commit('setSupplierFormDataAll', tenant.supplier);
                        } else {
                            self.$store.commit('setSupplierFormDataAll', {
                                entity_name: null,
                                phone_number: null,
                                point_of_contact: null,
                                email: null,
                                description: null,
                                postal_address: null,
                                physical_address: null,
                                postal_city: null,
                                physical_city: null,
                                postal_zipcode: null,
                                physical_zipcode: null,
                                image_path: null,
                                is_active: null,
                            });
                        }
                    }
                }
            }
        },
    },

    /************************************************************************************
     *                                    CREATED
     * **********************************************************************************/
    created() {
        const param = this.$route.params;
        const apiKeys = this.currentTenant.api_keys;

        this.quickbooksApiKey = apiKeys?.["allec-inventory"]?.quickbooks
        this.cloverApiKey = apiKeys?.["allec-inventory"]?.clover

        if (this.type == 'supplier') {
            let data = {
                key: "is_supplier",
                data: true,
            };

            this.$store.commit("setTenantsFrmData", data);
        }

        if (!param.tenant_id && !param.supplier_id) {
            this.$store.commit("clearTenantFrmData");
        }
    },

    /************************************************************************************
     *                                  MOUNTED
     * **********************************************************************************/
    mounted() { }
};
</script>